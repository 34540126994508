import React from "react";
import HeroSection from "../home/HeroSection";
import AboutUs from "../home/AboutUs";
import ExamsList from "../home/ExamList";
import HowItWorks from "../home/HowItWorks";
import Testimonials from "../home/Testimonials";
import CallToAction from "../home/CallToAction";
import PaymentPlans from "../home/PaymentPlans";

function Home() {
  return (
    <>
      <HeroSection />
      <AboutUs />
      <ExamsList />
      <HowItWorks />
      <Testimonials />
      <PaymentPlans></PaymentPlans>
      <CallToAction />
    </>
  );
}

export default Home;
