import React from "react";
import {
  Box,
  Heading,
  Text,
  VStack,
  Button,
  useColorModeValue,
} from "@chakra-ui/react";
import { Link } from "react-router-dom";

const CallToAction = () => {
  const bgColor = useColorModeValue("teal.500", "teal.300");
  const textColor = useColorModeValue("white", "gray.800");

  return (
    <Box
      py={20}
      px={8}
      bg={bgColor}
      color={textColor}
      textAlign="center"
      id="call-to-action"
    >
      <VStack spacing={8} maxW="3xl" mx="auto">
        <Heading as="h2" size="2xl" fontWeight="bold">
          Supercharge Your Exam Preparation with AI
        </Heading>
        <Text fontSize="xl" maxW="2xl">
          Unlock the full potential of AI to enhance your exam readiness. Our
          AI-driven agent offers personalized mock tests, interactive
          voice-assisted testing, instant feedback on PDF submissions and
          in-depth analytics tailored just for you.
        </Text>
        <Text fontSize="lg" maxW="2xl">
          Start today and take the first step towards acing your exams with our
          cutting-edge AI-powered tools designed to ensure your success!
        </Text>
        <VStack spacing={4}>
          <Button
            size="lg"
            bg="teal.500" // Background color for the button
            border="2px solid" // Adding a border
            borderColor="white" // Border color when not hovered
            _hover={{ bg: "teal.600", borderColor: "white" }} // Change background and keep the same border on hover
            color="white" // Text color
          >
            <Link
              to="/login"
              style={{
                color: "inherit",
                textDecoration: "none",
                width: "100%",
              }} // Inheriting the button's text color
            >
              Try AI-Powered Mock Test
            </Link>
          </Button>
        </VStack>
      </VStack>
    </Box>
  );
};

export default CallToAction;
